
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Head from "next/head";
import { GetStaticProps } from "next";
import { useSession } from "next-auth/react";
import { dehydrate, QueryClient, useQuery } from "@tanstack/react-query";
import { fetcher } from "lib/fetcher";
import { getEntries } from "lib/prismaQueries/getEntries";

import { Layout } from "components/Layout";
import Loading from "components/Loading";
import useTranslation from "next-translate/useTranslation";
import { ConnectedPosts } from "../components/Post/ConnectedPosts";
import { Fragment, useState, useEffect } from "react";
import { UnconnectedPosts } from "components/Post/UnconnectedPosts";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserInfoBanner from "components/UserInfoBanner";
import NewsBanner from "components/NewsBanner";
import { getEntriesForHome } from "lib/prismaQueries/getEntriesForHome";

export default function Home({}) {
  const { t } = useTranslation("common");
  const [hasNextMonthDates, setHasNextMonthDates] = useState(false);
  const { data: entries, isLoading } = useQuery(["entries"], () =>
    fetcher<Awaited<ReturnType<typeof getEntries>>>(`/api/entries`)
  );

  const { data: session, status } = useSession();

  const isConstruction = process.env.NEXT_PUBLIC_CONSTRUCTION === "true";

  /*   useEffect(() => {
    if (locale && defaultLocale) {
      return persistLocaleCookie(locale, defaultLocale);
    }
  }, [locale, defaultLocale]); */

  /* if (status === "loading") {
    return <Loading logInLoading />;
  } */

  // get the index of the current month
  const currentMonthIndex = new Date().getMonth();
  // Get the next month index
  const nextMonthIndex = (currentMonthIndex + 1) % 12;

  useEffect(() => {
    if (entries) {
      setHasNextMonthDates(
        entries?.some((obj) => {
          const bookDate = new Date(obj.bookDate);
          return bookDate.getMonth() === nextMonthIndex;
        })
      );
    }
  }, [entries, nextMonthIndex]);

  if (isLoading) {
    return <Loading />;
  }

  if (isConstruction) {
    return (
      <div>
        <Head>
          <title>{process.env.NEXT_PUBLIC_PRODUCTNAME}</title>
          <meta name="description" content={t("productDescription")} />
        </Head>
        <div>
          <main>
            <div className="my-3 text-center">
              <span className="left-[-5px] top-[-10px]   bg-gradient-to-r from-indigo-600 to-fuchsia-600 bg-clip-text px-1 py-1 font-black text-4xl text-slate-400 text-transparent md:px-2 md:text-5xl ">
                <span className="min-w-[22px] text-center ">
                  {t("construction")}
                </span>
              </span>
            </div>
          </main>
        </div>
        <ToastContainer />
        <footer></footer>
      </div>
    );
  }
  return (
    <div>
      <Head>
        <title>{process.env.NEXT_PUBLIC_PRODUCTNAME}</title>
        <meta name="description" content={t("productDescription")} />
      </Head>
      <Layout>
        <main>
          <UserInfoBanner
            activeSession={status !== "authenticated" ? false : true}
            hasNextMonthDates={hasNextMonthDates}
          />
          {session?.user?.benefits ? <NewsBanner /> : null}
          <div className="mt-12">
            {entries?.map((entry) => (
              <Fragment key={entry.id}>
                {entry?.connection?.id ? (
                  <>
                    <ConnectedPosts
                      id={entry?.connection?.id}
                      entries={entry?.connection?.entries}
                      capping={entry?.connection?.capping}
                    />
                  </>
                ) : (
                  <UnconnectedPosts entry={entry} />
                )}
              </Fragment>
            ))}
          </div>
          {entries?.length === 0 ? (
            <div className="my-3 text-center">
              <span className="left-[-5px] top-[-10px]   bg-gradient-to-r from-indigo-600 to-fuchsia-600 bg-clip-text px-1 py-1 font-black text-4xl text-slate-400 text-transparent md:px-2 md:text-5xl ">
                <span className="inline-block min-w-[22px] text-center ">
                  {t("nothingHere")}
                </span>
              </span>
            </div>
          ) : null}
        </main>
      </Layout>
      <ToastContainer />
      <footer></footer>
    </div>
  );
}

 const _getStaticProps: GetStaticProps = async () => {
  const queryClient = new QueryClient();
  const post = await getEntriesForHome("PUBLISHED");
  const serialized = JSON.parse(JSON.stringify(post));
  await queryClient.prefetchQuery(["entries"], () => serialized);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 10,
  };
};

/*
export async function getServerSideProps(context: GetServerSidePropsContext) {
  const queryClient = new QueryClient();
  const post = await getEntries("PUBLISHED");
  const serialized = JSON.parse(JSON.stringify(post));
  await queryClient.prefetchQuery(["entries"], () => serialized);
  const session = await getServerSession(context.req, context.res, authOptions);
  if (!session) {
    return {
      props: {
        dehydratedState: dehydrate(queryClient),
      },
    };
  }
  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      session,
    },
  };
}
 */


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  