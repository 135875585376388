import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import Cookies from "js-cookie";

import Balancer from "react-wrap-balancer";
import invariant from "tiny-invariant";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

export const getNewsShown = (id: string): string => {
  try {
    const newsCookie = Cookies.get(id);
    invariant(typeof newsCookie === "string");
    return newsCookie;
  } catch (e) {
    return "";
  }
};

const setNewsShown = (id: string) => {
  Cookies.set(id, "true", {
    expires: 365,
    secure: process.env.NODE_ENV === "production",
    sameSite: "strict",
    path: "/",
  });
};

export const NewsBanner = () => {
  const { t } = useTranslation("common");
  const cookieID = "bookit-news-0325";
  // set a state to see if the user wants to close the banner
  const [newsOpen, setNewsOpen] = useState(true);
  if (new Date() > new Date("2025-03-31")) {
    return null;
  }
  if (getNewsShown(cookieID) === "true" || newsOpen === false) return <></>;

  // Check if current date is after March 2025

  return (
    <div className="my-1 mb-12 justify-center">
      <div
        className={clsx(
          "relative flex w-full justify-between rounded-[5px] bg-gradient-to-r from-yellow-600/60 to-yellow-300/60 p-6 text-xl text-slate-800"
        )}
      >
        <div className="absolute right-2 top-2">
          <XMarkIcon
            className="h-5 w-5 cursor-pointer text-slate-800"
            onClick={() => {
              setNewsShown(cookieID);
              setNewsOpen(false);
            }}
          />
        </div>
        <div className="mr-6 flex items-center justify-center text-4xl text-slate-800">
          News
        </div>
        <div className="items-left justify-right text-md flex max-w-[800px] flex-col text-slate-800">
          <Balancer>
            <p className="mb-2">{t("news0325_1")}</p>
            <p>{t("news0325_2")}</p>
          </Balancer>
        </div>
      </div>
    </div>
  );
};

export default NewsBanner;
